<template>
  <section class="pb-8" id="contacto">
    <v-container fluid class="mt-3">
      <v-row justify="center">
        <v-col cols="10">
          <v-row  justify="space-around">
            <v-col cols="12" sm="4">
              <v-col cols="12">
                  <h1 class="font-weight-light display-2">Contáctanos</h1>
                  <h3 class="font-weight-light mt-3">
                    Estamos en Cúcuta, Norte de Santander, Colombia.
                  </h3>
                  
                  <h3 class="font-weight-light mt-3">
                    Puedes comunicarte con nosotros a través de la siguiente dirección de correo electrónico 
                  </h3>
                  
                  <h3 class="font-weight-light">
                    <div style="overflow-wrap: anywhere">
                    fundacionredhumanist@gmail.com
                  </div>
                  </h3>
              </v-col>
              
            </v-col>
            <v-row justify="center">
            
              <!--<v-col cols="12" md="5" class="d-none d-md-flex">
                <v-img
                  src="@/assets/img/contacto.svg"
                  class="d-block ml-auto mr-auto"
                  max-width="400px"
                />

                
              </v-col> -->
              <v-col cols="12" sm="7">
                <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                  <v-text-field
                      v-model="name"
                      :rules="nameRules"
                      label="Nombre"
                      required
                  ></v-text-field>

                  <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-correo"
                      required
                  ></v-text-field>

                  <v-textarea
                      v-model="message"
                      :rules="messageRules"
                      label="Mensaje"
                      required
                  />

                  <v-btn
                      :disabled="!valid"
                      color="primary"
                      :dark="valid"
                      rounded
                      block
                      class="mt-3"
                      @click="submit"
                  >
                    Enviar
                  </v-btn>
                </v-form>
              </v-col>

            </v-row>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.svg"/>
    </div>
    <v-snackbar
        v-model="snackbar.enabled"
        timeout="10000"
        right
        top
        :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar.enabled = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<style scoped>
#contacto {
  background-color: #f4f7f5;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2rem;
  width: 100%;
  overflow: hidden;
}

</style>

<script>
// import {db} from "@/main"
import emailjs from "@emailjs/browser";

export default {
  data: () => ({
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "El campo nombre es obligatorio",
      (v) => (v && v.length >= 6) || "El nombre debe tener más de 6 caracteres",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "El campo E-correo es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El Correo debe ser válido",
    ],
    message: "",
    messageRules: [
      (v) => !!v || "El campo mensaje es obligatorio",
      (v) => (v && v.length >= 10) || "Mínimo de 10 caracteres",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: '',
      color: ''
    }
  }),
  methods: {
    created(){
      emailjs.init("iWjKPzELCRNYvNtqj");
    },
    submit(e) {
      e.preventDefault();
      this.valid = false
      try {
       emailjs.sendForm("service_uasu5uq", "template_agp6vbf", "form", "iWjKPzELCRNYvNtqj")
        .then((/*result*/) => {
            //console.log('SUCCESS!', result.text);
            
            this.snackbar.text = "Mensaje enviado exitosamente"
            this.snackbar.color = "primary"
            this.snackbar.enabled = true

            //limpia el formulario
            //this.message = ''
            
            //espera 1 minuto para volver  a dejar enviar de nuevo el correo
            setTimeout( () => this.valid = true, 60000)
            
        }, (error) => {
            console.log("FAILED...", error.text);
            this.snackbar.text = "Error al enviar el mensaje"
            this.snackbar.color = "red"
            this.snackbar.enabled = true

            this.valid = true
        });

      } catch(error) {
          console.log({error})
          this.valid = true
      }

     /* db.collection("contactData").add({
        name: this.name,
        email: this.email,
        message: this.message
      }).then(() => {
        this.snackbar.text = "Mensagem enviada com sucesso"
        this.snackbar.color = "success"
        this.snackbar.enabled = true
      }).catch(() => {
        this.snackbar.text = "Erro ao enviar mensagem"
        this.snackbar.color = "danger"
        this.snackbar.enabled = true
      })*/
    }
  }
};
</script>
