<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      dark
    >
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/img/logo3.png" alt="Logo" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title">RedHumanist</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          @click="$vuetify.goTo(link)"
        >
          <v-list-item-icon class="justify-center">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{
              text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      dark
      class="px-15"
      :class="{ expand: flat }"
    >

      <v-toolbar-title>
        <v-img src="@/assets/img/logo.png" max-width="220px" />
      </v-toolbar-title>
      <v-spacer />
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        
        v-if="isXs"
      />
      <div v-else>
        <v-btn text @click="$vuetify.goTo('#inicio')">
          <span class="mr-2">Inicio</span>
        </v-btn>
        <v-btn text @click="$vuetify.goTo('#zona')">
          <span class="mr-2">Zona de Acción</span>
        </v-btn>
        <v-btn text @click="$vuetify.goTo('#quienes-somos')">
          <span class="mr-2">Quienes Somos</span>
        </v-btn>
        <v-btn text @click="$vuetify.goTo('#desafios')">
          <span class="mr-2">Desafios</span>
        </v-btn>
        <v-btn rounded outlined text @click="$vuetify.goTo('#contacto')">
          <span class="mr-2">Contactenos</span>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 20px;
}
</style>

<script>
export default {
  data: () => ({
    drawer: null,
    isXs: false,
    items: [
      ["mdi-home-outline", "Inicio", "#inicio"],
      ["mdi-vanish", "Zona de Acción", "#zona"],
      ["mdi-information-outline", "Quienes Somos", "#quienes-somos"],
      ["mdi-book-open-page-variant", "Desafios", "#desafios"],
      ["mdi-email-outline", "Contactenos", "#contacto"],
    ],
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
