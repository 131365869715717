<template>
  <section id="inicio" >
    <v-parallax dark src="@/assets/img/bgHero.jpg" height="600">
      <v-row align="center" justify="center" >
        <v-col cols="10" >
          <v-row align="center" justify="center">
            <v-col  >
              <h1 class="display-2 d-none d-sm-flex font-weight-bold mb-4">Fundación RedHumanist</h1>
               <h1 class="display-1 d-flex d-sm-none font-weight-bold mb-4">Fundación RedHumanist</h1>
              <v-btn
                rounded
                outlined
                large
                dark
                @click="$vuetify.goTo('#quienes-somos')"
                class="mt-5"
              >
                Conocer más
                <v-icon class="ml-2">mdi-arrow-down</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="svg-border-waves text-white">
        <v-img src="@/assets/img/borderWaves.svg" />
      </div>
    </v-parallax>
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row  justify="space-around">
            <!-- <v-col cols="12" class="text-center">
              <h1 class="font-weight-light display-2">Title</h1>
              <h1 class="font-weight-light">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </h1>
            </v-col> -->
            <v-col
              cols="12"
              sm="4"
              class="text-justify"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"

                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  
                  
                  <div>
                    <span v-show="!feature.show">{{ feature.text.substring(0, 140) }}</span>
                    <span v-show="feature.show">{{ feature.text }}</span>
                    <a
                      color="secondary"
                      outlined
                      @click="feature.show = !feature.show"
                    >
                      <span v-if="feature.show"> Menos</span>
                      <span v-else> ... Más</span>
                    </a>
                  </div>
                  <v-card-actions>
                    
                  </v-card-actions>


                  
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    
    <div class="svg-border-waves">
      <img src="~@/assets/img/wave2.svg" />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      
      features: [
        {
          show: false,
          img: require("@/assets/img/icon2.png"),
          title: "Misión",
          text: "Promover acciones que permitan el crecimiento de una sociedad, convirtiéndola en equitativa y sustentable, con bases para construir capacidades y habilidades basados en el enfoque de DDHH, los cuales cobijan a las personas que habitan en los sectores menos favorecidos de un territorio, brindándoles la oportunidad de participar en los procesos de desarrollo de la comunidad y del país e impulsando acciones que abordan diversas problemáticas evidenciadas que permitan la mitigación de las mismas en las fronteras en estudio.",
        },
        {
          show: false,
          img: require("@/assets/img/icon1.png"),
          title: "Visión",
          text: "Ser una organización social, activa, ágil, innovadora, empoderadora, inclusiva e igualitaria que responda con proactividad a los desafíos que se le planteen y en el desarrollo de proyectos de vanguardia que beneficien a quienes más lo necesitan; sobresaliendo por su efectividad, dedicación, capacidad, tecnicismo y el altruismo de sus miembros, comprometidas(os) siempre con la promoción, prevención y protección de los DDHH, pero sobre todo con el respeto y la empatía social.",
        },
        {
          show: false,
          img: require("@/assets/img/icon3.png"),
          title: "Principios",
          text: "En la fundación se trabajará bajo los principios de democracia, solidaridad, equidad, justicia, transparencia, innovación y en general por los derechos fundamentales de los seres humanos contenidos en la Constitución política y en los derechos nacionales e internacionales.",
        },
      ],
      methods: {    activateReadMore(){        this.show = true;    },}
    };
  },
};
</script>



<style>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#inicio {
  z-index: 0;width: 100%;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.2);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>

<style>
section {
  position: relative;
}
</style>
